import React, { useRef } from 'react'
import { Helmet } from 'react-helmet'
import { Alert, PageHeader } from 'antd'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/app/redux/interface'
import Form from '@antegma/accallio-cloud-react-components/lib/app/components/Form/Form'
import { EFormItemType } from '@antegma/accallio-cloud-react-components/lib/app/components/Form/types'
import { useStore } from 'react-redux'

const Profile = (): JSX.Element => {
  const store = useStore()
  const appState = store.getState() as TDefaultSagas
  const { user } = appState.oidc
  const formRef = useRef()

  const formItems = [
    {
      type: EFormItemType.input,
      name: 'firstname',
      formItemOptions: {
        label: 'Firstname',
      },
      fieldDecoratorOptions: {
        initialValue: user?.profile.given_name,
        rules: [
          {
            required: true,
          },
        ],
      },
    },
    {
      type: EFormItemType.input,
      name: 'lastname',
      formItemOptions: {
        label: 'Family Name',
      },
      fieldDecoratorOptions: {
        initialValue: user?.profile.family_name,
        rules: [
          {
            required: true,
          },
        ],
      },
    },
    {
      type: EFormItemType.input,
      disabled: true,
      name: 'email',
      formItemOptions: {
        label: 'Email',
        help: (
          <>
            To update your email address, click{' '}
            <a href="#" target="_blank">
              here
            </a>
          </>
        ),
      },
      fieldDecoratorOptions: {
        initialValue: user?.profile.email,
        rules: [
          {
            required: true,
          },
        ],
      },
    },
  ]

  return (
    <>
      <Helmet title="User Profile" />
      <PageHeader title="Update your Profile">
        <Alert message="This page is under construction." type="info" style={{ margin: 20 }} />
        <Form items={formItems} wrappedComponentRef={formRef} />
      </PageHeader>
    </>
  )
}

export default {
  name: 'Profile',
  routeProps: {
    path: '/profile',
    component: Profile,
  },
  breadcrumbProps: {
    title: 'User Profile',
  },
}
