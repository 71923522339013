import React from 'react'
import { Helmet } from 'react-helmet'
import { Card, Divider, List, PageHeader, Typography } from 'antd'
import Meta from 'antd/lib/card/Meta'
// import { Link } from "react-router-dom";

type TListItem = {
  title: string
  description?: string
  url?: string
}

const Home = (): JSX.Element => {
  const data: TListItem[] = [
    {
      title: 'Tenant Admin',
      description: 'Manage Users & Connections',
      url: 'https://admin.dev.cloud.accallio.com/',
    },
    {
      title: 'Social Cloud',
      description: 'Manage social media streams',
      url: 'https://social.dev.cloud.accallio.com/',
    },
    {
      title: 'Title 3',
    },
    {
      title: 'Title 4',
    },
  ]

  const handleCardClick = (item: TListItem): void => {
    console.log(item.url)
    if (item.url) {
      window.open(item.url, '_blank')
    }
  }

  const renderItem = (item: TListItem): JSX.Element => {
    return (
      <List.Item>
        <Card
          cover={<img alt={item.title} src={`https://via.placeholder.com/1024x400/3b9fe7/ffffff?text=${item.title}`} />}
          hoverable
          onClick={(): void => handleCardClick(item)}
          style={{
            width: '100%',
          }}
        >
          <Meta description={item.description} title={item.title} />
        </Card>
      </List.Item>
    )
  }

  const listGrid = {
    gutter: 16 as const,
    xs: 1 as const,
    sm: 2 as const,
    md: 4 as const,
    lg: 4 as const,
    xl: 4 as const,
    xxl: 6 as const,
  }

  return (
    <>
      <Helmet title="Dashboard" />
      <PageHeader title="Dashboard">
        <Typography.Title level={4}>Apps</Typography.Title>
        <List<TListItem> dataSource={data} grid={listGrid} renderItem={renderItem} />
        <Divider />
        <Typography.Title level={4}>Projects</Typography.Title>
        <List<TListItem> dataSource={data} grid={listGrid} renderItem={renderItem} />
      </PageHeader>
    </>
  )
}

export default {
  name: 'Home',
  routeProps: {
    path: '/home',
    component: Home,
  },
  navProps: {
    title: 'Dashboard',
  },
}
